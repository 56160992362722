import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import { useConnect, useDisconnect, useAccount, useSignMessage } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { ENVIRONMENT } from "../App";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  let navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [
    openVerifyMetamaskExtensionModal,
    setOpenVerifyMetamaskExtensionModal,
  ] = useState(false);
  const [openSwitchNetworkModal, setOpenSwitchNetworkModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const { connect, isSuccess } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useAccount();
  const chainIdHex = ENVIRONMENT == "prod" ? "0x89" : "0x13881";

  useEffect(() => {
    const getArtist = async () => {
      const artistRef = collection(db, "artists");
      const q = query(artistRef, where("walletAddress", "==", address));
      const querySnapshot = await getDocs(q);
      let response;

      querySnapshot.forEach((doc) => {
        response = doc.data();
      });

      setCurrentUser(response);
    };

    if (isConnected) {
      getArtist();
    } else {
      setCurrentUser(null);
      setIsLoading(false);
    }
  }, [isConnected]);

  useEffect(() => {
    const verifyArtist = async () => {
      const artistRef = collection(db, "artists");
      const q = query(artistRef, where("walletAddress", "==", address));
      const querySnapshot = await getDocs(q);

      if (querySnapshot._snapshot.docs.size === 0) {
        Logout();
        setOpenErrorModal(true);
      } else {
        navigate("/profile");
        setIsLoading(false);
      }
    };

    if (isSuccess) {
      verifyArtist();
    }
  }, [isSuccess]);

  const Login = () => {
    connect();
  };

  const Logout = () => {
    disconnect();

    navigate("/");
  };

  const VerifyMetamaskExtension = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      return Login();
    } else {
      if (isBrowser) {
        return setOpenVerifyMetamaskExtensionModal(true);
      } else {
        window.location.replace(
          "https://metamask.app.link/dapp/withdrawal.the-gallery.io/"
        );
      }
    }
  };

  const SwitchNetwork = () => {
    window.ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainIdHex }],
      })
      .then(() => {
        setOpenSwitchNetworkModal(false);
      })
      .catch((switchError) => {
        if (
          switchError.code === 4902 ||
          switchError.data?.originalError?.code === 4902
        ) {
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                ENVIRONMENT == "prod"
                  ? {
                      chainId: "0x89",
                      chainName: "Polygon Mainnet",
                      nativeCurrency: {
                        name: "MATIC",
                        decimals: 18,
                        symbol: "MATIC",
                      },
                      rpcUrls: ["https://polygon-mainnet.infura.io"],
                      blockExplorerUrls: ["https://polygonscan.com/"],
                    }
                  : {
                      chainId: "0x13881",
                      chainName: "Mumbai",
                      nativeCurrency: {
                        name: "MATIC",
                        decimals: 18,
                        symbol: "MATIC",
                      },
                      rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                      blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
                    },
              ],
            })
            .then(() => {
              setOpenSwitchNetworkModal(false);
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (switchError.code === 4001) {
          setOpenSwitchNetworkModal(true);
        } else {
          console.log(switchError);
        }
      });
  };

  const memodValue = useMemo(
    () => ({
      currentUser,
      isLoading,
      openVerifyMetamaskExtensionModal,
      openSwitchNetworkModal,
      openErrorModal,
      setIsLoading,
      setResult,
      setOpenVerifyMetamaskExtensionModal,
      setOpenSwitchNetworkModal,
      setOpenErrorModal,
      Login,
      Logout,
      VerifyMetamaskExtension,
      SwitchNetwork,
    }),
    [
      currentUser,
      isLoading,
      result,
      openVerifyMetamaskExtensionModal,
      openSwitchNetworkModal,
      openErrorModal,
    ]
  );

  return (
    <AuthContext.Provider value={memodValue}>{children}</AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
