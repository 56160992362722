import React, { useEffect, useState } from "react";
import { TbWallet } from "react-icons/tb";
import { AiOutlinePoweroff } from "react-icons/ai";
import useAuth from "../hooks/useAuth";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import Modal from "@mui/material/Modal";
import { TG_LP_URL } from "../App";

const Header = ({ moreStyle, logo }) => {
  const { currentUser, Logout, VerifyMetamaskExtension } = useAuth();
  const [openBurgerMenuModal, setOpenBurgerMenuModal] = useState(false);
  const dateYear = new Date().getFullYear();

  return (
    <div
      className={
        "w-full top-0 z-50 px-6 md:px-8 lg:px-4 bg-transparent " + moreStyle
      }
    >
      <div className="flex justify-between space-x-3 w-full h-[70px] lg:h-[100px] items-center">
        <div className="flex space-x-3 lg:space-x-0 items-center">
          <AiOutlineMenu
            className="flex lg:hidden w-8 h-8 md:w-9 md:h-9 text-white cursor-pointer"
            onClick={() => setOpenBurgerMenuModal(true)}
          />

          {logo && (
            <Link to="/">
              <img
                src="/images/logo.png"
                className="w-[140px]  md:w-[160px] lg:w-[180px] h-[40px] md:h-[45px] lg:h-[50px]"
                alt=""
              />
            </Link>
          )}
        </div>

        <ul className="flex items-center space-x-2 md:space-x-3">
          {currentUser && (
            <li>
              <Link to="/profile">
                <img
                  src="/images/tg-profile.png"
                  className="w-10 h-10 object-cover rounded-full cursor-pointer"
                  alt=""
                />
              </Link>
            </li>
          )}

          {currentUser ? (
            <li
              className="border-2 rounded-full p-2 cursor-pointer"
              onClick={Logout}
            >
              <AiOutlinePoweroff className="w-5 h-5 text-white" />
            </li>
          ) : (
            <li
              className="border-2 rounded-full p-2 cursor-pointer"
              onClick={VerifyMetamaskExtension}
            >
              <TbWallet className="w-5 h-5 text-white" />
            </li>
          )}

          <li className="hidden md:flex">
            <p className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px] md:max-w-[300px] lg:max-w-full py-2 px-4 md:px-6 lg:px-8 rounded-full font-semibold border-2 border-white text-white">
              {currentUser
                ? currentUser.walletAddress
                : "Address of the artist"}
            </p>
          </li>
        </ul>
      </div>

      <Modal
        open={openBurgerMenuModal}
        onClose={() => {
          setOpenBurgerMenuModal(false);
        }}
        className="flex lg:hidden"
      >
        <div className="flex flex-col bg-[#00263a] w-full md:w-[300px] h-full outline-none">
          <div className="flex justify-start md:justify-end items-center p-4">
            <RxCross2
              className="w-11 h-11 cursor-pointer text-white"
              onClick={() => {
                setOpenBurgerMenuModal(false);
              }}
            />
          </div>

          <div className="flex flex-col py-10 px-6 space-y-10 w-full text-white">
            <div className="w-full items-center">
              <img className="w-40" src="/images/logo.png" alt="" />
            </div>

            <a
              className="text-lg text-[15px] sm:text-[16px]"
              href={`${TG_LP_URL}/about-us`}
              target="_blank"
              rel="noreferrer"
            >
              About us
            </a>

            <a
              className="text-lg text-[15px] sm:text-[16px]"
              href={`${TG_LP_URL}/terms-and-conditions`}
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>

            <a
              className="text-lg text-[15px] sm:text-[16px]"
              href={`${TG_LP_URL}/faq`}
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </a>

            <p className="text-lg text-[15px] sm:text-[16px]">
              © {dateYear} - The Gallery • V1.0.1
            </p>

            <div className="flex text-white space-x-2 items-center">
              <a
                href="https://zealy.io/c/thegalleryio/invite/h4xMRsxqU5oEUnPL88e5E"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/Zealy.png" className="w-7 h-7" />
              </a>

              <a
                href="https://twitter.com/TGallery_NFT"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/Twitter.png" className="w-7 h-7" />
              </a>

              <a
                href="https://www.instagram.com/the_gallery_nft/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/Instagram.png" className="w-7 h-7" />
              </a>

              <a
                href="https://www.facebook.com/TGalleryNFT"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/Facebook.png" className="w-7 h-7" />
              </a>

              <a
                href="https://www.linkedin.com/company/thenftgallery/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/Linkedin.png" className="w-7 h-7" />
              </a>

              <a
                href={`${TG_LP_URL}/TheGallery-Whitepaper.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/Whitepaper.png" className="w-7 h-7" />
              </a>

              <a href={`${TG_LP_URL}/faq`} target="_blank" rel="noreferrer">
                <img src="/images/FAQ.png" className="w-7 h-7" />
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
