import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import StickBar from "../components/StickBar";

const Home = () => {
  return (
    <div className="flex flex-col h-screen">
      <Header moreStyle="sticky bg-[#00263a]" logo={true} />

      <div className="flex">
        <StickBar moreStyle="sticky" />

        <div className="flex w-full h-[calc(100vh-70px)] lg:h-[calc(100vh-100px)] items-center justify-center">
          <img
            src="/images/logo.png"
            className="w-[300px] md:w-[400px] lg:w-[500px]"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
