import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./hooks/useAuth";
import { BrowserRouter } from "react-router-dom";
import { WagmiConfig, createClient, configureChains } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { polygon, polygonMumbai } from 'wagmi/chains'
import { ENVIRONMENT } from "./App";

const { chains, provider, webSocketProvider } = configureChains(
  [ENVIRONMENT == "prod" ? polygon : polygonMumbai],
  [publicProvider()]
);

 
const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
})
 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiConfig client={client}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </WagmiConfig>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
