import React from "react";
import { TG_LP_URL } from "../App";

const StickBar = ({ moreStyle }) => {
  const dateYear = new Date().getFullYear();

  return (
    <div
      className={
        "absolute w-[110px] py-4 space-y-[150px] hidden lg:inline " + moreStyle
      }
    >
      <div
        className="flex items-center text-white space-y-10 w-full "
        style={{ writingMode: "vertical-rl" }}
      >
        <a
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          href={`${TG_LP_URL}/about-us`}
          target="_blank"
          rel="noreferrer"
        >
          About us
        </a>

        <a
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          href={`${TG_LP_URL}/terms-and-conditions`}
          target="_blank"
          rel="noreferrer"
        >
          Terms & Conditions
        </a>

        <a
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          href={`${TG_LP_URL}/faq`}
          target="_blank"
          rel="noreferrer"
        >
          FAQ
        </a>

        <p className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none">
          <span className="rotate-90">©</span> {dateYear} - The Gallery • V1.0.1
        </p>
      </div>

      <div className="flex flex-col text-white space-y-2 items-center">
        <a
          href="https://zealy.io/c/thegalleryio/invite/h4xMRsxqU5oEUnPL88e5E"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Zealy.png" className="w-7 h-7" />
        </a>

        <a
          href="https://twitter.com/TGallery_NFT"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Twitter.png" className="w-7 h-7" />
        </a>

        <a
          href="https://www.instagram.com/the_gallery_nft/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Instagram.png" className="w-7 h-7" />
        </a>

        <a
          href="https://www.facebook.com/TGalleryNFT"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Facebook.png" className="w-7 h-7" />
        </a>

        <a
          href="https://www.linkedin.com/company/thenftgallery/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Linkedin.png" className="w-7 h-7" />
        </a>

        <a
          href={`${TG_LP_URL}/TheGallery-Whitepaper.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Whitepaper.png" className="w-7 h-7" />
        </a>

        <a href={`${TG_LP_URL}/faq`} target="_blank" rel="noreferrer">
          <img src="/images/FAQ.png" className="w-7 h-7" />
        </a>
      </div>
    </div>
  );
};

export default StickBar;
